import React from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <section className="pb-4">
      <div className="container">
        <div className="row mb-5">
          <div style={{ textAlign: "center" }} className="col-lg-12 mb-4 mb-lg-0">
            <img src="./../images/logo_inverted.svg" className="mb-3" alt="" />
            <p
              style={{ letterSpacing: "0.08px", lineHeight: "140%" }}
              className="normal font__size--16 text__16-1024 color__gray-3"
            >
              We are a dynamic and trusted software house,
              <br />
              steering the maritime industry into the digital future.{" "}
            </p>
          </div>
          {false && <div className="col-6 col-lg  mb-4 mb-lg-0 offset-xl-1"></div>}
          {false && (
            <div className="col-lg-6 col-xl-5">
              {false && <h5 className="medium font__size--14 text__16-1024 mb-0 color__gray-1">Newsletter</h5>}

              {false && (
                <div className="d-flex align-items-center">
                  <input
                    type="text"
                    className="form-control normal font__size--14 text__14-1024 wrapper__field-input"
                    placeholder="Enter your email address"
                  />
                  <button className="medium font__size--16 text__16-1024 btn btn__green shadow color__white ml-3 flex-shrink-0">
                    Subscribe
                  </button>
                </div>
              )}
            </div>
          )}
        </div>

        <div className="wrapper__footer-down position-relative">
          {/* <div className="sosmed mb-3 mb-lg-0">
                        <a href="#!"><img src="./../images/sf (1).png" alt="" /></a>
                        <a href="#!"><img src="./../images/sf (2).png" alt="" /></a>
                        <a href="#!"><img src="./../images/sf (3).png" alt="" /></a>
                        <a href="#!"><img src="./../images/sf (4).png" alt="" /></a>
                        <a href="#!"><img src="./../images/sf (5).png" alt="" /></a>
                    </div> */}
          <p className="mb-0 normal font__size--14 text__14-1024 color__gray-3 order-lg-1 order-12">
            © {new Date().getFullYear()} DigiTer.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Footer;
