import React, { Fragment } from "react";
import Footer from "../../component/Other/Footer";
import Navbar from "../../component/Other/Navbar";
import SecContact from "./SecContact";
import OurMission from "./OurMission";
import OurService from "./OurService";
import Testimonial from "./Testimonial";
import AboutUs from "./AboutUs";

import { ReactComponent as InnovationImage } from "../../assets/images/ic (1).svg";
import { ReactComponent as ReliabilityImage } from "../../assets/images/ic (2).svg";
import { ReactComponent as PartnerImage } from "../../assets/images/ic (3).svg";

const Index = (props) => {
  return (
    <Fragment>
      <div className="overflow-hidden">
        <Navbar cart={true} />

        <section
          style={{
            scrollMarginTop: "96px",
            marginTop: "105px",
            marginBottom: "105px",
          }}
          id="who-we-are"
          className="position-relative z-2"
        >
          <div style={{ position: "relative" }} className="container">
            <div style={{ height: "585px", width: "100%" }} className="wrapper">
              <div className="heroImage">&nbsp;</div>

              <div className="contentTest">
                <div className="text-left">
                  <h1 className="bold font__size--56 text__50-1024 text__50-sm text__50-xs syne mb-0 color__gray-1">
                    <span className="position-relative">
                      Empower Your
                      <img src="./../images/Graphic.png" className="images__line-head" alt="" />
                    </span>{" "}
                    <span
                      style={{
                        display: "inline-block",
                        backgroundColor: "#03C49BCC",
                      }}
                    >
                      &nbsp;Maritime&nbsp;
                    </span>
                    <br className="d-none d-lg-block" /> <span className="position-relative">Operations</span>
                  </h1>

                  <p className="normal font__size--16 text__18-1024 color__gray-2 my-4">
                    Embark on a voyage with DigiTer, where&nbsp;
                    <br className="d-none d-lg-block" />
                    innovation sails on the high seas. As a dynamic&nbsp;
                    <br className="d-none d-lg-block" />
                    and trusted software house, we're steering the&nbsp;
                    <br className="d-none d-lg-block" />
                    maritime industry into the digital future.&nbsp;
                    <br />
                    <br />
                  </p>

                  <a
                    style={{ whiteSpace: "nowrap" }}
                    className="medium font__size--16 text__16-1024 btn btn__green shadow color__white"
                    rel="noopener"
                    href="#how-to-contact"
                  >
                    Set Sail with Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        {false && (
          <section
            style={{
              scrollMarginTop: "96px",
              backgroundImage: "url(./../images/ImageVslTec.png)",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right",
              backgroundSize: "50%",
              marginTop: "185px",
            }}
            id="who-we-are"
            className="position-relative z-2"
          >
            <div className="container">
              <div className="text-left">
                <h1 className="bold font__size--56 text__50-1024 text__50-sm text__50-xs syne mb-0 color__gray-1">
                  <span className="position-relative">
                    Empower Your
                    <img src="./../images/Graphic.png" className="images__line-head" alt="" />
                  </span>{" "}
                  <span
                    style={{
                      display: "inline-block",
                      backgroundColor: "#03C49BCC",
                    }}
                  >
                    &nbsp;Maritime&nbsp;
                  </span>
                  <br className="d-none d-lg-block" /> <span className="position-relative">Operations</span>
                </h1>

                <p className="normal font__size--16 text__18-1024 color__gray-2 my-4">
                  Embark on a voyage with DigiTer, where&nbsp;
                  <br className="d-none d-lg-block" />
                  innovation sails on the high seas. As a dynamic&nbsp;
                  <br className="d-none d-lg-block" />
                  and trusted software house, we're steering the&nbsp;
                  <br className="d-none d-lg-block" />
                  maritime industry into the digital future.&nbsp;
                  <br />
                  <br />
                  <br />
                  <br />
                </p>

                <a
                  style={{ whiteSpace: "nowrap" }}
                  className="medium font__size--16 text__16-1024 btn btn__green shadow color__white"
                  rel="noopener"
                  href="#how-to-contact"
                >
                  Set Sail with Us
                </a>
              </div>
            </div>
          </section>
        )}

        <section>
          <div className="container">
            <div className="row">
              <div className="my-lg-auto col-lg-5 text-center text-lg-left mb-5">
                <img src="./../images/asa.png" alt="" />
                <h3 className="bold font__size--40 text__40-1024 text__40-md text__40-mm syne mb-0 color__gray-1">
                  Partnering with Shipping Companies Across the Globe
                </h3>
              </div>
              <div
                style={{ backgroundColor: "#03C49B", width: 657, height: 70 }}
                className="my-lg-auto col-lg-7 text-right text-lg-left mb-5"
              >
                &nbsp;
              </div>
            </div>
          </div>
        </section>

        <section style={{ padding: "0 0 72px 0" }}>
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-4 mb-4">
                <div className="wrapper__service-wrap">
                  <InnovationImage fill="#7D7CA1" className="svgImage whitenFillOnHover" />
                  <h4
                    style={{ paddingTop: "20px" }}
                    className="bold font__size--24 text__24-1024 color__gray-1 syne whitenColorOnHover"
                  >
                    Innovation
                  </h4>
                  <p className="normal font__size--16 text__16-1024  color__gray-1 whitenColorOnHover">
                    We leverage cutting-edge technology and fresh thinking to develop solutions that drive the digital
                    transformation of the maritime industry.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 mb-4">
                <div className="wrapper__service-wrap">
                  <ReliabilityImage stroke="#7D7CA1" className="svgImage whitenStrokeOnHover" />
                  <h4
                    style={{ paddingTop: "20px" }}
                    className="bold font__size--24 text__24-1024 color__gray-1 syne whitenColorOnHover"
                  >
                    Reliability
                  </h4>
                  <p className="normal font__size--16 text__16-1024 color__gray-1 whitenColorOnHover">
                    We pride ourselves on being a dependable partner for our clients. From our high-end solutions to our
                    ongoing support, we deliver on our promises and exceed expectations.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 mb-4">
                <div className="wrapper__service-wrap">
                  <PartnerImage stroke="#7D7CA1" className="svgImage whitenStrokeOnHover" />
                  <h4
                    style={{ paddingTop: "20px" }}
                    className="bold font__size--24 text__24-1024 color__gray-1 syne whitenColorOnHover"
                  >
                    Partner-Centricity
                  </h4>
                  <p className="normal font__size--16 text__16-1024 color__gray-1 whitenColorOnHover">
                    Our solutions are tailored to meet their specific needs, helping them navigate the digital seas with
                    confidence and efficiency.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <OurMission></OurMission>
        <OurService></OurService>

        <AboutUs></AboutUs>
        <Testimonial></Testimonial>
        <SecContact></SecContact>

        <Footer />
      </div>
    </Fragment>
  );
};

export default Index;
