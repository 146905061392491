import React from "react";
import { useRef } from "react";
import { useState } from "react";

const SecContact = () => {
  const upload = useRef(null);
  const [file, setFile] = useState(null);
  const onClickUpload = (e) => {
    upload.current.click();
  };
  const onChangeHandle = (e) => {
    setFile(e.target.files[0].name);
  };
  return (
    <section id="how-to-contact" className="bg__black">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 mb-5 my-lg-auto">
            <div className="mb-5">
              <h2 className="bold font__size--48 text__48-1024 text__48-md text__48-sm color__white syne">
                Looking to find out more?
              </h2>
              <p className="normal font__size--18 text__18-1024 color__white opacity__8">
                Schedule a consultation with one of our experts to discuss your <br />
                business needs and how we can help.
              </p>
            </div>
            <a
              className="medium font__size--16 text__16-1024 btn btn__green shadow color__white"
              href="mailto:support@digi-ter.com"
            >
              Contact us
            </a>
          </div>
          <div className=" my-auto" style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
            <div style={{ backgroundColor: "#1E1D5A" }} className="wrapper__contact-detail mx-auto">
              <div className="text-center">
                <div
                // className="mb-5"
                >
                  <p className="normal font__size--14 text__14-1024 color__white opacity__5">Our Email</p>
                  <h3 className="bold font__size--20 text__20-1024 syne color__brown">
                    <a style={{ color: "#03C49B" }} href="mailto:support@digi-ter.com">
                      support@digi-ter.com
                    </a>
                  </h3>
                </div>
                {/* <div>
                  <p className="normal font__size--14 text__14-1024 color__white opacity__5">Social Media</p>
                  <div className="sosmed">
                    <a href="#!">
                      <img src="./../images/list (1).png" alt="" />
                    </a>
                    <a href="#!">
                      <img src="./../images/list (2).png" alt="" />
                    </a>
                    <a href="#!">
                      <img src="./../images/list (3).png" alt="" />
                    </a>
                    <a href="#!">
                      <img src="./../images/list (4).png" alt="" />
                    </a>
                    <a href="#!">
                      <img src="./../images/list (5).png" alt="" />
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SecContact;
